.plant-box {
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.8); 
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2); 
  box-shadow: 0px 4px 8px rgba(35, 164, 85, 0.3); 
  transition: all 0.3s ease; 
  cursor: pointer; 
  transition: background-color 300ms;

}

.plant-box:hover {
  background-color: rgba(35, 164, 85, 0.4); 
  box-shadow: 0px 6px 12px rgba(35, 164, 85, 0.5); 

}

.plant-box:active { 
  background-color: rgba(0, 255, 98, 0.4);
}

.plant-box:hover .plant-box-tooltip {
  visibility: visible;
}

.plant-box-tooltip {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  opacity: 0.8;
  text-align: left;
  border-radius: 6px;
  padding-left: 5px;
  font-size: 12px;


  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  transform: translateY(-110%);
  top: 0;
  left: -50%
}

.copy-all {
  position: absolute;
  top: 50px;
  left: 0;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy-all-button {
  background-color: rgba(35, 164, 85, .6);
  border: 1px solid rgba(35, 164, 85, .6);
}

.copy-all-button:hover Button {
  background-color: rgba(35, 164, 85, 1);
  border: 1px solid rgba(35, 164, 85, 1);
}