.interook-section-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.interhook-section:hover {
  cursor: pointer;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 2px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.react-flow__handle {
  opacity: 0;
}

.lane {
  text-align: right;
}

.sublane {
  text-align: right;
}

.lane-separator {
  background-color: aqua;
}

.buffer-lane {
  cursor: wait;
  background: linear-gradient(110deg, #ececec91 8%, #f5f5f571 18%, #ececec73 33%);
  border-radius: 2px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.overview-map-cell {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.overview-map-cell:hover {
  cursor: pointer;
  opacity: 0.5;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  /* transition the cell's opacity in an animation loop */

}

.overview-map-cell-empty:hover {
  cursor: grab;
  pointer-events: none;
}


.loader {
  width: 15px;
  height:15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}


.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.greenhouse-background {
  background-color: #e5e5f7;
  opacity: 1;
  background-size: 20px 20px;
  background-position: 0 0,10px 10px;
  z-index: 2;
}

.greenhouse-background:hover {
  cursor: grab;
}


/* svg g rect {
  filter: url(#shadow);
} */

.popup-card-item {
  border: 1px solid #434344;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid #7d9cd2;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: underline;
  height: 185px;
  width: 100%;
}

.popup-card-item:hover {
  cursor: pointer;
  background: radial-gradient(circle, rgba(238,174,202,.7) 0%, rgba(148,187,233,.7) 100%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.popup-card-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 10px;
}

.popup-card-item-title {
  margin-top: 1px;
  padding: 5px;
}

.snapshot-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show-plant-list-button{
  background-color: #7d9cd2;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  width: 60%;
  border: 1px solid #bdd0f1;
}

.show-expert-annotations-list-button{
  background-color: #d27dce;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  width: 60%;
  border: 1px solid #bdd0f1;
  margin-bottom: 10px;
}

.show-plant-list-button:hover {
  cursor: pointer;
  background: radial-gradient(circle, rgba(238,174,202,.7) 0%, rgba(148,187,233,.7) 100%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.show-expert-annotations-list-button:hover {
  cursor: pointer;
  background: radial-gradient(circle, rgba(238,174,202,.7) 0%, rgba(148,187,233,.7) 100%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.expert-annotation-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #7d9cd2;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}

.expert-annotation-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.expert-annotation-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-size: cover;
}
.no-data-found {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time-picker {
  border: 1px solid #7d9cd2;
}



#root-map-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(65, 65, 65, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#root-map-container::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#root-map-container::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(65, 65, 65, 0.3);
	background-color: #969696;
}

.plant-card{
  background-color: rgb(35, 35, 80);
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  border: 1px solid rgb(155, 154, 201);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.plant-image-and-stats-container {
  display: flex;
  flex-direction: row;
  height: 80%;
  border: 1px solid #e0ebfd;
  justify-content: center;
  align-items: center;
}

.plant-card-image {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  width: 80%;

}

.plant-card-content {
  height: 100%;
  background-color: white;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.plant-card-stats {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-right: 3px;
  
}

.plant-card-stats-label {
  font-size: 14px;
  font-style: bold;
  background-color: #44107a;
  height: 33%;

}

.plant-card-title {
  font-size: 16px;
  color: black;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px black;
  font-weight: bold;
}

.plant-card-title-container {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}




/* HTML: <div class="loader"></div> */
.plant-image-loader  {
  width: 10px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.snapshot-image-loader {
  width: 10px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}




/* .discarded-key:hover  {
  opacity: 1;
}

.analyzed-key:hover  {
  opacity: 1;
}

.failed-key:hover  {
  opacity: 1;
}

.incomplete-key:hover  {
  opacity: 1;
} */

.snapshot-map-keys {
  opacity: 0.5;
}

.snapshot-map-keys:hover {
  opacity: 1;
}

/* show text on hover with a transition */
.snapshot-map-keys:hover text {
  display: block;
  transition: 0.5s;
}

.snapshot-map-keys text {
  display: none;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.annotation-map-keys {
  opacity: 0.5;
}

.annotation-map-keys:hover {
  opacity: 1;
}

/* show text on hover with a transition */
.annotation-map-keys:hover .annotation-key-label {
  display: block;
  transition: 0.5s;
}

.annotation-map-keys .annotation-key-label {
  display: none;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}