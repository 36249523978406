@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {
  margin: auto;
  background: #394252;
  padding: 10px;
  box-shadow: rgba(144, 144, 190) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 1px solid #7d9cd2;  
  border-radius: 5px;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-content::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(44, 44, 44, 0.3);
	border-radius: 10px;
	background-color: #bebebe;
}

.popup-content::-webkit-scrollbar
{
	width: 12px;
	background-color: #3d3d3d;
}

.popup-content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(44, 44, 44, 0.3);
	background-color: #261a35;
}


.popup-arrow {
  color: #394252;
}
[role='tooltip'].popup-content {
  width: 300px;
  height: 800px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);

}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-button {
  width: 100%;
  height: 100%;
}

.popup-button:hover {
  background: #f5f5f5;
  cursor: pointer;
}


.button-loading {
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 2px;
  background-size: 200% 100%;
}
 
.button-loading:hover {
  cursor: pointer;
}
