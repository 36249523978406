
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.batch {
  border-radius: 5px;
}

.batch:hover div {
  opacity: 0.9 !important;
  z-index: 1;
  cursor: pointer;
}

.tooltip {
  display: inline-block;
  border-bottom: 1px dotted black; 
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  font-size: large;
  position: fixed;
  right: 50px;
  top: 100px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: fixed;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Tooltip text */
.tray-tooltip .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  font-size: large;
  position: fixed;
  right: 50px;
  top: 100px;
}

.tray-tooltip .tooltiptext::after {
  content: " ";
  position: fixed;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tray-tooltip:hover .tooltiptext {
  visibility: visible;
}

.tray-tooltip:hover{
  cursor: pointer;
  opacity: .8 !important;
}

.dropdowns-container {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.dropdowns-container > * {
  flex: 0 0 auto;
  width: 200px;
}

.hook-numbers > * {
  flex: 1 1 auto;
}

.calibration-tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: red;
}

.calibration-tooltip:hover {
  background-color: green !important;
  cursor: crosshair;
  z-index: 1000 !important;

}

.calibration-tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1000 !important;
  width: 250px;
  bottom: 300%;
  left: 50%;
  margin-left: -125px;
}

.calibration-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  z-index: 1000 !important;
}

.calibration-tooltip:hover .tooltiptext {
  visibility: visible;
}